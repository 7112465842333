import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Seo from "../components/seo";
import Nav from "../components/Nav/Nav";
import * as ACFModules from "../modules/Modules";
import PostTwoColumnText from "../modules/PostTwoColumnText/PostTwoColumnText";
import Fallback from "../modules/Fallback";

export default function Post({ data: { wpPost, nextPost, firstPost } }) {
  const {
    postModules: { modules, readingTime },
    title,
    seo,
    uri,
    date,
    author,
    categories,
  } = wpPost;
  // const posts = allWpPost;
  // posts.nodes.sort((a, b) => a.menuOrder - b.menuOrder);

  // const currentIndex = posts.nodes.findIndex((node) => node.id === wpPost.id);
  const category = categories?.nodes[0].name || "";
  const authorName = author?.node?.name || "";

  return (
    <>
      <Seo seo={seo} />
      <Nav position="top" slug={`/posts${uri}`} templateType="stories" />
      <main className="single-post">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title} - Seesaw Studio</title>
        </Helmet>

        {modules &&
          modules.map((module, id) => {
            if (Object.keys(module).length > 0) {
              const moduleName = module.fieldGroupName.replace(
                "Post_Postmodules_Modules_",
                ""
              );
              const Component = ACFModules[moduleName] || Fallback;

              switch (moduleName) {
                case "NextPost":
                  if (nextPost.nodes.length) {
                    return (
                      <Component
                        key={id}
                        {...module}
                        nextPostArticle={
                          module.nextPost ? module.nextPost : nextPost.nodes[0]
                        }
                      />
                    );
                  } else if (firstPost.nodes.length) {
                    return (
                      <Component
                        key={id}
                        {...module}
                        nextPostArticle={
                          module.nextPost ? module.nextPost : firstPost.nodes[0]
                        }
                      />
                    );
                  }

                  return <Component key={id} {...module} />;

                case "PostHero":
                  return (
                    <Component
                      key={id}
                      title={title}
                      date={date}
                      readingTime={readingTime}
                      category={category}
                      author={authorName}
                      {...module}
                    />
                  );

                case "TwoColumnText":
                  return (
                    <PostTwoColumnText
                      key={id}
                      {...module}
                      parallaxEffect={false}
                    />
                  );

                default:
                  return <Component key={id} {...module} />;
              }
            }
          })}
      </main>
    </>
  );
}

export const query = graphql`
  query ($id: String!, $menuOrder: Int) {
    wpPost(id: { eq: $id }) {
      id
      title
      uri
      date(formatString: "DD-MM-YY")
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      postModules {
        modules {
          ... on WpPost_Postmodules_Modules_PostHero {
            backgroundColour
            content
            fieldGroupName
            heading
            image {
              publicUrl
              mediaItemUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    quality: 100
                    breakpoints: [750, 1080, 1366, 1920, 2560, 3000]
                  )
                }
              }
              altText
            }
            categoryUrl
            infoList {
              label
              name
            }
          }
          ... on WpPost_Postmodules_Modules_TwoColumnText {
            backgroundcolour
            fieldGroupName
            textType
            textLeft
            imageSize
            imageLeft {
              publicUrl
              mediaItemUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
              altText
            }
            videoLeft
            topImageSpacing
            imageTop {
              publicUrl
              mediaItemUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
              altText
            }
            videoTop
            textRight
            imageBottom {
              publicUrl
              mediaItemUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
              altText
              caption
            }
            videoBottom
            padding {
              paddingBottom
              paddingBottomMobile
              paddingTop
              paddingTopMobile
            }
          }
          ... on WpPost_Postmodules_Modules_NextPost {
            backgroundColour
            fieldGroupName
            rightImage {
              publicUrl
              mediaItemUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
              altText
              caption
            }
            nextPostArticle {
              ... on WpPost {
                id
                title
                uri
                featuredImage {
                  node {
                    altText
                    publicUrl
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPost_Postmodules_Modules_RelatedPosts {
            backgroundColour
            fieldGroupName
            relatedPosts {
              ... on WpPost {
                id
                title
                uri
                excerpt
                contentTypeName
                featuredImage {
                  node {
                    altText
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          aspectRatio: 0.9090909090909091
                        )
                      }
                    }
                  }
                }
              }
              ... on WpProject {
                id
                title
                uri
                excerpt
                contentTypeName
                featuredImage {
                  node {
                    altText
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          aspectRatio: 0.9090909090909091
                        )
                      }
                    }
                  }
                }
              }
            }
            taxonomyCategory {
              posts {
                nodes {
                  id
                  title
                  uri
                  excerpt
                  contentTypeName
                  featuredImage {
                    node {
                      altText
                      publicUrl
                      localFile {
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: FULL_WIDTH
                            aspectRatio: 0.9090909090909091
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        readingTime
      }
    }
    nextPost: allWpPost(
      sort: { fields: menuOrder, order: ASC }
      filter: { menuOrder: { gt: $menuOrder } }
      limit: 1
    ) {
      nodes {
        id
        title
        uri
        menuOrder
        featuredImage {
          node {
            altText
            localFile {
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        postColour {
          postColour
        }
      }
    }
    firstPost: allWpPost(sort: { fields: menuOrder, order: ASC }, limit: 1) {
      nodes {
        id
        title
        uri
        menuOrder
        featuredImage {
          node {
            altText
            localFile {
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        postColour {
          postColour
        }
      }
    }
  }
`;
